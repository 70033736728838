@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  background-color: black;
  color: #f8fafc;
  margin: 0;
  font-family: "Montserrat", sans-serif;
}
