.app-container {
  position: relative;
}

.snap-y {
  scroll-snap-type: y mandatory;
}

.snap-start {
  scroll-snap-align: start;
}

.section-container {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 2rem;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.section-container.fadeIn {
  opacity: 1;
}

.subtitle {
  font-size: 1.5rem;
  margin-top: 0.5rem;
  color: #f8fafc;
}

.scroll-indicator {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: #f8fafc;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pill {
  width: 1.5rem;
  height: 2.5rem;
  border: 2px solid #f8fafc;
  border-radius: 1rem;
  position: relative;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dot {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #f8fafc;
  border-radius: 50%;
  position: absolute;
  animation: moveDot 2s infinite;
}

@keyframes moveDot {
  0%,
  100% {
    top: 10%;
  }
  50% {
    top: 70%;
  }
}

@media (max-width: 768px) {
  .subtitle {
    font-size: 1.5rem;
  }

  .section-container h1 {
    font-size: 4rem;
  }

  .section-container .title {
    white-space: pre-line;
  }

  .logos-container {
    flex-direction: column;
    align-items: center;
  }

  .scroll-indicator {
    bottom: 160px; 
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}